body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@layer base {
    /* width */
    ::-webkit-scrollbar {
      @apply w-1
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      @apply bg-inherit
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      @apply bg-white dark:bg-violet-600 rounded-xl
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      @apply bg-violet-700
    }
  }